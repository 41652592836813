import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"
import logo from "images/sourcery.svg"
import mobileBarsIcon from "images/mobile-menu.svg"

const MenuModal = (props) => {
  return (
    <div
      className="bg-black opacity-70 w-[100vw] h-[100vh] z-40 fixed"
      {...props}
    />
  )
}

const SubMenuCardItem = ({
  bgColor,
  children,
  description,
  link,
  title,
  setNavbarOpen,
}) => {
  return (
    <div className="flex items-center min-h-[8.5rem]" id="cardItem">
      <div className="p-5 group hover:bg-black-300 max-w-md mx-[1rem] rounded-md">
        <div
          className="cursor-pointer flex gap-6 items-center justify-start border-r-2 border-black-500 last:border-0 h-8"
          onClick={() => {
            navigate(link)
            setNavbarOpen(false)
          }}
        >
          <div
            className={`${bgColor} flex justify-center h-8 w-8 px-[2rem] items-center rounded-lg group-hover:opacity-90`}
          >
            {children}
          </div>
          <div className="max-w-[20rem]">
            <div className="font-bold text-white tracking-tight leading-6">
              {title}
            </div>
            <p className="text-black-200 group-hover:text-white">
              {description}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const NavBar2 = () => {
  const [navbarOpen, setNavbarOpen] = useState(false)

  const menuItems = [
    { title: "Code Reviews", url: "/" },
    { title: "Fix Production Issues", url: "/sentinel" },
    { title: "Pricing", url: "/pricing/" },
    { title: "Blog", url: "/blog/" },
    { title: "Docs", url: "https://docs.sourcery.ai/", external: true },
  ]

  return (
    <div className="flex flex-col">
      {navbarOpen && <MenuModal onClick={() => setNavbarOpen(false)} />}
      <nav className="bg-black-500 fixed top-0 z-50 w-full overflow-x-hidden">
        <div className="flex justify-between items-center w-full px-5 lg:px-16 py-6 max-w-screen-2xl mx-auto">
          <Link to="/" className="inline-flex items-center whitespace-nowrap">
            <img src={logo} alt="Sourcery Logo" className="h-[40px]" />
          </Link>
          <div className="hidden lg:flex items-center gap-4">
            {menuItems.map((item, index) =>
              item.external ? (
                <a
                  key={index}
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-mono uppercase text-sm text-black-200 hover:text-orange-200 px-8 py-2"
                >
                  {item.title}
                </a>
              ) : (
                <Link
                  key={index}
                  to={item.url}
                  className="font-mono uppercase text-sm text-black-200 hover:text-orange-200 px-8 py-2"
                >
                  {item.title}
                </Link>
              )
            )}
            <a
              href={process.env.SOURCERY_DASHBOARD_URL}
              className="font-mono uppercase flex py-5 px-6 w-full justify-between text-orange-200 hover:text-white"
            >
              Log In →
            </a>
          </div>
          {/* Mobile Menu Icon */}
          <button
            className="lg:hidden flex items-center"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <img src={mobileBarsIcon} alt="Menu" className="h-6 w-6" />
          </button>
        </div>
        {navbarOpen && (
          <div className="lg:hidden bg-gray-200 w-full">
            {menuItems.map((item, index) => (
              <div
                key={index}
                className="text-white py-2 px-4 hover:bg-gray-300"
              >
                {item.external ? (
                  <a href={item.url} target="_blank" rel="noopener noreferrer">
                    {item.title}
                  </a>
                ) : (
                  <Link to={item.url}>{item.title}</Link>
                )}
              </div>
            ))}
            <a
              href={process.env.SOURCERY_DASHBOARD_URL + "/login/"}
              className="flex items-center justify-between bg-orange-200 text-black w-full py-2 px-4"
            >
              Log In
            </a>
          </div>
        )}
      </nav>
    </div>
  )
}

SubMenuCardItem.propTypes = {
  bgColor: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  setNavbarOpen: PropTypes.func.isRequired,
}

export default NavBar2
